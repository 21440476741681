'use strict';

import Session from '../utils/session';
import * as Dialogs from '../utils/dialogs';

export default class {
  init() {
    this._listeners = {};
    this._controller = undefined;
    this._token = undefined;

    if (!window.FirebasePlugin) {
      return;
    }

    let self = this;

    window.FirebasePlugin.setAnalyticsCollectionEnabled(false);
    window.FirebasePlugin.grantPermission();

    window.FirebasePlugin.onTokenRefresh(function(token) {
      self._register(token);
    }, function(error) {
      self._error(error);
    });

    window.FirebasePlugin.onNotificationOpen(function(notification) {
      self._notification(notification);
    }, function(error) {
      self._error(error);
    });
  }

  setController(controller) {
    this._controller = controller;
  }

  unregister() {
    if (window.FirebasePlugin) {
      window.FirebasePlugin.onTokenRefresh(undefined, undefined);
      window.FirebasePlugin.onNotificationOpen(undefined, undefined);
      window.FirebasePlugin.unregister();
    }

    return this._deleteCurrentToken();
  }

  on(event, func) {
    if (event in this._listeners) {
      this._listeners[event].push(func);
    } else {
      this._listeners[event] = [func];
    }
  }

  off(event, func) {
    if (!(event in this._listeners)) {
      return;
    }

    let eventListeners = this._listeners[event];
    for (let index in eventListeners) {
      if (eventListeners[index] === func) {
        eventListeners.splice(index, 1);
        break;
      }
    }
  }

  trigger(event, data) {
    if (!(event in this._listeners)) {
      return;
    }

    let eventListeners = this._listeners[event];
    for (let index in eventListeners) {
      eventListeners[index](data);
    }
  }

  clearBadge() {
    if (window.FirebasePlugin) {
      window.FirebasePlugin.setBadgeNumber(0);
    }
  }

  setBadge(count) {
    if (window.FirebasePlugin) {
      window.FirebasePlugin.setBadgeNumber(count);
    }
  }

  get token() {
    return this._token;
  }

  get tokenType () {
    // Using firebase, all tokens are of type GCM. The API will send to these devices using Firebase.
    return 'gcm';
  }

  _register(token) {
    let self = this;

    console.log('Push register ', token);

    let data;
    let tokenType = this.tokenType;
    if (tokenType != null) {
      data = {
        token: token,
        tokenType: tokenType,
        userId: Session.userId
      };
    } else {
      return;
    }

    setTimeout(function() {
      self._token = token;
      Session.request('POST', 'push/token', data, undefined)
        .catch(function(error) {
          console.log('Push token registration error: ', error);
        });
    }, 250);
  }

  _deleteCurrentToken() {
    let self = this;

    if (!self._token) {
      return new Promise(function(resolve) {
        resolve();
      });
    }

    return Session.request('DELETE', 'push/token?token=' + encodeURIComponent(self._token), undefined, undefined)
      .then(function() {
        console.log('Push notification device token was deleted.');
      }, function(e) {
        console.log('Unable to delete push notification device token.', e);
      })
      .always(function() {
        self._token = undefined;
      });
  }

  _notification(data) {
    console.log('Push notification: ', data);

    let title = data['title'];
    let message = data['message'];
    let extraData = {
      event: 'new-session'
    };

    // For iOS, manually display notifications while the app is in the foreground.
    if (data['tap'] === false) {
      Dialogs.confirm(message, ['Negeer', 'Bekijk'], title)
        .then(function(buttonIndex) {
          if (buttonIndex === 1) {
            this._executeNotification(extraData);
          }
        }.bind(this));
    } else {
      this._executeNotification(extraData);
    }
  }

  _executeNotification(extraData) {
    this.clearBadge();

    // Trigger event.
    if (extraData['event']) {
      this.trigger(extraData['event'], extraData);
    }

    // Call controller function.
    if (this._controller && (extraData['event'] in this._controller)) {
      this._controller[extraData['event']](extraData);
    }
  }

  _error(e) {
    console.error('Notification error:', e);
  }
}
