'use strict';

import jQuery from 'jquery';

import App from '../app';

const API_URL = '@ENV.API_URL@';

const API_TIMEOUT = 30000;

class Session {
  constructor () {
    this._isLoggedIn = false;
    this._isAnonymous = false;
    this._userId = null;
    this._passwordRules = null;
    this._profile = {};

    // Retrieve settings.
    let settings = window.localStorage.getItem('session');
    if (settings) {
      this._settings = JSON.parse(settings);
    } else {
      this._settings = {};
    }
  }

  /**
   * Sets a settings item and updates local storage.
   *
   * @param string key
   * @param any    value
   */
  setItem (key, value) {
    this._settings[key] = value;
    window.localStorage.setItem('session', JSON.stringify(this._settings));
  }

  /**
   * Removes a settings item and updates local storage.
   *
   * @param  string key The key to remove.
   */
  deleteItem (key) {
    delete this._settings[key];
    window.localStorage.setItem('session', JSON.stringify(this._settings));
  }

  /**
   * Clears all session items
   */
  clearItems () {
    this._settings = {};
    window.localStorage.removeItem('session');
  }

  /**
   * Validates the session's credentials.
   *
   * @return Promise
   */
  validate () {
    let session = this;

    let promise = new Promise(function (resolve, reject) {
      if (session._isLoggedIn) {
        resolve();
        return;
      }

      if (!('userId' in session._settings) || !session._settings['userId']) {
        reject();
        return;
      }

      session._userId = session._settings['userId'];

      session.request('GET', 'user/get_account_info?userId=' + encodeURIComponent(session._userId))
        .done(function (data) {
          session._profile = data;
          session._isLoggedIn = true;
          App.initPushNotifications();
          resolve();
        })
        .fail(function () {
          App.unregisterPushNotifications();
          session._profile = {};
          session._isLoggedIn = false;
          session._userId = null;
          session.clearItems();
          reject();
        });
    });

    return promise;
  }

  /**
   * Performs a user login. The session data is stored for reuse.
   *
   * @param  string username The username to log in with.
   * @param  string password The password to log in with.
   *
   * @return Promise
   */
  login (username, password) {
    let session = this;

    session._userId = null;
    session._isLoggedIn = false;
    session._isAnonymous = false;
    session._passwordRules = null;

    let promise = new Promise(function (resolve, reject) {
      if (!session.isConnected()) {
        reject();
        return;
      }

      jQuery.ajax({
        url: API_URL + 'user/login',
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          username: username,
          password: password
        }),
        timeout: API_TIMEOUT
      })
        .done(function (data, status, xhr) {
          if (!data.userId) {
            reject();
            return;
          }

          session.setItem('userId', data.userId);
          session.setItem('username', username);

          session._isLoggedIn = true;
          session._userId = data.userId;

          session.request('GET', 'user/get_account_info?userId=' + encodeURIComponent(session._userId))
            .done(function (profileData) {
              session._profile = profileData;
              App.initPushNotifications();
              resolve({
                'username': username,
                'userId': data.userId,
                'passwordUpdateRequired': data.passwordUpdateRequired,
                'hasApprovedGdpr': profileData.hasApprovedGdpr
              });
            })
            .fail(function (xhr, status, error) {
              reject(xhr.status, error);
            });
        })
        .fail(function (xhr, status, error) {
          reject(xhr.status, error);
        });
    });

    return promise;
  }

  /**
   * Logs the user out. All session related information is destroyed if the request succeeded.
   * Note that the returned promise will always be resolved and never rejected.
   *
   * @return Promise
   */
  logout () {
    let session = this;

    // Delete push notification token before logging out.
    App.unregisterPushNotifications();

    let promise = new Promise(function (resolve, reject) {
      if (!session.isConnected()) {
        reject();
        return;
      }

      session.request('POST', 'user/logout', {userId: session.userId})
        .done(function () {
          session._isLoggedIn = false;
          session._isAnonymous = false;
          session._userId = null;
          session._passwordRules = null;
          session._profile = {};

          session.clearItems();

          resolve();
        })
        .fail(function () {
          resolve();
        });
    });

    return promise;
  }

  /**
   * Creates a new 'anonymous' session, without any user information
   *
   * @return Promise
   */
  createAnonymous () {
    let session = this;

    session._isLoggedIn = false;
    session._passwordRules = null;
    session.clearItems();

    let promise = new Promise(function (resolve, reject) {
      if (session.isLoggedIn) {
        reject();
        return;
      }

      session.request('GET', 'user/get_anonymous')
        .done(function (data, status, xhr) {
          session._isLoggedIn = true;
          session._isAnonymous = true;
          session._userId = data.userId;
          session._profile = {};

          resolve({
            'userId': data.userId
          });
        })
        .fail(function (xhr, status, error) {
          reject(xhr.status);
        });
    });

    return promise;
  }

  /**
   * Sends an AJAX request with some default parameters.
   *
   * @param  string method   HTTP method.
   * @param  string endpoint URL endpoint name.
   * @param  any data        Optional data to send.
   *
   * @return jqXHR
   */
  request (method, endpoint, data, originalOptions) {
    let origin = window.cordova ? 'app' : 'site';
    if (App.kioskMode) {
      origin = 'kiosk';
    }
    let options = {
      method: method,
      url: API_URL + endpoint,
      timeout: API_TIMEOUT,
      headers: {
        'LANGUAGE':App.Language,
        'X-API-Origin': origin
      }
    };
    options = jQuery.extend({}, originalOptions, options);

    if (method === 'POST' || method === 'PUT') {
      options.contentType = 'application/json';
      if (data) {
        options.data = JSON.stringify(data);
      }
    } else if (data) {
      options.data = data;
    }

    return jQuery.ajax(options).catch(function(error) {

      // If the request timed out, show a message and rethrow the error with status 200 so that the promise chain
      // will still stop but no further warnings will be handled.
      if (error.statusText == 'timeout') {
        Dialogs.alert('De verbinding met de server deed er te lang over. Controleer je internet verbinding of probeer het later nog eens.');
        App.isBusy(false);

        error.status = 200;
      }

      throw error;
    });
  }

  /**
   * Helper function to retrieve user's password rules.
   *
   * @return Promise
   */
  getPasswordRules () {
    let session = this;
    let promise = new Promise(function (resolve, reject) {
      if (session._passwordRules) {
        resolve(session._passwordRules);
      }

      if (!session._userId) {
        reject();
        return;
      }

      session.request('POST', 'user/get_password_rules', {userId: session._userId})
        .done(function (data, status, xhr) {
          session._passwordRules = data;
          resolve(data);
        })
        .fail(function (xhr, status, error) {
          reject(xhr.status);
        });
    });

    return promise;
  }

  isConnected () {
    if (window.cordova) {
      return (navigator.connection.type !== Connection.NONE);
    } else {
      return true;
    }
  }

  /**
   * Returns true if the user is currently logged in.
   *
   * @return Boolean
   */
  get isLoggedIn () {
    return this._isLoggedIn;
  }

  get userId () {
    return this._userId;
  }

  get isAnonymous () {
    return this._isAnonymous;
  }

  /**
   * Returns the email address, undefined if it is not stored.
   *
   * @return string
   */
  get username () {
    return this._settings['username'];
  }

  get profile () {
    return this._profile;
  }
}

export default new Session();
