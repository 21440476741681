import App from '../app';

class I18n {
  constructor() {
    this.strings = {

      'nl': { //Default
        'completed': 'Voltooid',
        'next': 'Volgende',
        'previous': 'Vorige',
        'stop_test': 'Stoppen met de test',
        'information': 'Informatie',
        'back': 'Terug',
        'and': 'en',
        'ready': 'Klaar!',
        'test_result_improve': 'Uit jouw snelle test blijkt dat er ruimte voor verbetering is op het gebied van',
        'test_result_good': 'Uit de snelle test blijkt dat je goed bezig bent. De MijnBreincoach app helpt je om je hersengezondheid op peil te houden.',
        'thanks_result_page': 'Bedankt voor het invullen van de snelle test! Wil je op een leuke manier aan de slag met jouw verbeterpunten? Ga dan naar mijnbreincoach.eu of download de app.',
        'retake': 'Test opnieuw invullen',
        'app_name':'MijnBreincoach',

        'connection_msg':'De internetverbinding is verbroken. Om juist te functioneren heeft MijnBreincoach een internetverbinding nodig.',
        'error_find_session':'Geen actieve sessies gevonden.',
        'error_start_session':'Kan geen actieve sessie ophalen.',
        'error_start_test':'Er is een fout opgetreden bij het starten van de LIBRA test.',
        'short_test_restrict':'De korte LIBRA test is niet beschikbaar wanneer je ingelogd bent.',
        'error_test_early_end': 'De test is vroegtijdig geëindigd.',
        'error_test_start':'Er is een fout opgetreden bij het starten van de test.',

        'referral_list_work': 'Je werkt op een leuke manier aan gezonde(re) hersenen',
        'referral_list_advice': 'Je krijgt persoonlijk advies',
        'referral_list_subject': 'Je kiest zelf waar, wanneer en met welk onderwerp je aan de slag gaat',
        'referral_list_messgae': 'Je ontvangt iedere dag een kort berichtje: een ‘weetje’, quizvraag, tip of uitdaging',

        'error_accept_privacy': 'Kan het akkoord niet opslaan',
        'navigation_error':'Er is een fout opgetreden bij het navigeren.',
        'error_send_answer':'Er is een fout opgetreden bij het versturen van het antwoord.',
        'min_digits': 'Het :attribute veld moet minimaal :min_digits cijfer(s) bevatten.',
        'min_lowers':'Het :attribute veld moet minimaal :min_lowers kleine letter(s) bevatten.',
        'min_uppers': 'Het :attribute veld moet minimaal :min_uppers hoofdletter(s) bevatten',
        'min_specialchars':'Het :attribute veld moet minimaal :min_specialchars speciale karakter(s) bevatten.',
        'min_selected':'Selecteer minimaal :min_selected opties in het :attribute veld.',
        'max_selected':'Selecteer maximaal :max_selected opties in het :attribute veld.',
        'answer': 'antwoord'
      },
      'en': { //English
        'completed': 'Completed',
        'next': 'Next',
        'previous': 'Previous',
        'stop_test': 'Stop this test',
        'information': 'Information',
        'back': 'Back',
        'and': 'and',
        'ready': 'Ready!',
        'test_result_improve': 'Your short test shows that there is room for improvement in the areas of ',
        'test_result_good': 'Your short test shows that you are doing well. Keep this up!',
        'thanks_result_page': 'Thank you for completing the short test. We hope that you are motivated to keep your brain fit and that you will take on the challenge to start working on your room-for-improvement.',
        'retake': 'Take the test again',
        'app_name': 'MyBraincoach',
        'connection_msg':'No internet connection. MyBraincoach requires an active connection to function.',
        'error_find_session':'No active session found.',
        'error_start_session':'Cannot load active session.',
        'error_start_test':'An error occurred while starting the LIBRA test.',
        'short_test_restrict':'The short LIBRA test is unavailable when logged in.',
        'error_test_early_end': 'The test ended prematurely.',
        'error_test_start':'An error occurred while starting the test.',

        'referral_list_work': 'Ready to be translated!',
        'referral_list_advice': 'Ready to be translated!',
        'referral_list_subject': 'Ready to be translated!',
        'referral_list_messgae': 'Ready to be translated!',

        'error_accept_privacy': 'error while saving document.',
        'navigation_error':'A navigation error occurred.',
        'error_send_answer':'An error occurred, Answer could not be send.',
        'min_digits': 'The field :attribute should contain a minimum of :min_digits digits.',
        'min_lowers':'The field :attribute should contain a minimum of :min_lowers lowercase letters.',
        'min_uppers':'The field :attribute should contain a minimum of :min_uppers Uppercase letters.',
        'min_specialchars':'The field :attribute should contain a minimum of :min_specialchars special characters.',
        'min_selected':'Select at least :min_selected options in the :attribute field.',
        'max_selected':'Select :max_selected optios at maximum in the :attribute field.',
        'answer': 'answer'
      },
      'no': { //Norsk
        'completed': 'Fullført',
        'next': 'Neste',
        'previous': 'Forrige',
        'stop_test': 'Stopp testen',
        'information': 'Informasjon',
        'back': 'Tilbake',
        'and': 'og',
        'ready': 'Ferdig!',
        'test_result_improve': 'Denne korte testen viser at det er rom for forbedring når det gjelder ',
        'test_result_good': 'Denne korte testen viser at du har en sunn livsstil. Fortsett med det! ',
        'thanks_result_page': 'Takk for at du tok denne korte testen. Vi håper du er motivert til å holde hjernen i god form, og at du vil ta grep der det er rom for forbedring.',
        'retake': 'Ta testen på nytt',
        'app_name': 'MyBraincoach',
        'connection_msg':'Ingen Internett-tilkobling. MyBraincoach krever en aktiv forbindelse til funksjon.',
        'error_find_session':'Ingen aktive økten funnet.',
        'error_start_session':'Kan ikke laste aktiv økt.',
        'error_start_test':'En feil under start av Vekten testen.',
        'short_test_restrict':'Den korte LIBRA testen er utilgjengelig når du er logget inn.',
        'error_test_early_end': 'Testen ble avsluttet for tidlig.',
        'error_test_start':'En feil under start av testen.',

        'referral_list_work': '',
        'referral_list_advice': '',
        'referral_list_subject': '',
        'referral_list_messgae': '',

        'error_accept_privacy': 'Feil under lagring dokument.',
        'navigation_error':'En navigasjonsfeil.',
        'error_send_answer':'Det oppstod en feil, Svar kunne ikke sendes.',
        'min_digits': 'Feltet :attribute bør inneholde et minimum av :min_digits sifre.',
        'min_lowers':'Feltet :attribute bør inneholde et minimum av :min_lowers små bokstaver.',
        'min_uppers':'Feltet :attribute bør inneholde et minimum av :min_uppers Store bokstaver.',
        'min_specialchars':'Feltet :attribute bør inneholde et minimum av :min_specialchars spesialtegn.',
        'min_selected':'Velg minst :min_selected alternativer i :attribute feltet.',
        'max_selected':'Velg :max_selected Alternativene på maks i :attribute feltet.',
        'answer': 'svar'
      }

      // 'NL': {
      //   'completed': 'Voltooid',
      //   'next': 'Volgende',
      //   'previous': 'Vorige',
      //   'stop_test': 'Stoppen met de test',
      //   'information': 'Informatie',
      //   'back': 'Terug',
      //   'and': 'en',
      //   'test_result_improve': 'Uit jouw snelle test blijkt dat er ruimte voor verbetering is op het gebied van',
      //   'test_result_good': 'Uit de snelle test blijkt dat je goed bezig bent. De MijnBreincoach app helpt je om je hersengezondheid op peil te houden.',
      //   'system_unknown_error': 'Beim Start der App ist während der Phase "@stage" ein schwerwiegender Fehler aufgetreten.'
      // },
      // 'fr': {
      //   'system_no_connection': 'La connexion internet a été interrompue. Pour bien fonctionner, Myinlife a besoin d\'une connexion internet.',
      //   'system_button_ok': 'OK',
      //   'system_button_cancel': 'Annulez',
      //   'system_dialog_no': 'Non',
      //   'system_dialog_yes': 'Oui',
      //   'system_session_expired': 'Votre session a expiré. Vous devez vous reconnecter pour des raions de sécurité.',
      //   'system_no_translations': 'Les traductions n\'ont pas pu être chargées.',
      //   'system_internet_required': 'Une connexion internet est nécessaire pour Myinlife.',
      //   'system_timeout': 'La demande a expiré. Vérifiez votre connexion Internet et réessayez.',
      //   'system_unknown_error': 'Une erreur fatale s\'est produite lors de la tentative de démarrage de l\'application, lors de l\'étape "@stage".'
      // }
    };

    this.language = '@ENV.INTLANG@';
  }

  loadFromData(data) {
    this.strings = data;
  }

  setLanguage(lang) {
    this.language = lang;
  }

  get(key, options) {
    if (!this.strings[this.language]) {
      return '{' + this.language + ':' + key + '}';
    }

    let trans = this.strings[this.language][key];
    if (trans === undefined || trans === '') {
      return '{' + this.language + ':' + key + '}';
    }

    for (let option in options) {
      trans = trans.replace(new RegExp('@' + option, 'g'), options[option]);
    }

    return trans;
  }

  getPreferredLanguage() {
    let i18n = this;

    if (navigator.globalization === undefined) {
      console.warn('No globalization support. Using en as preferred language.');

      return Promise.resolve('en');
    }

    return new Promise(function(resolve, reject) {
      navigator.globalization.getPreferredLanguage(
        function (language) {
          let lang = i18n.language;
          let sub = language.value.substr(0, 2);

          if (sub == 'fr' || sub == 'de' || sub == 'nl' || sub == 'en') {
            lang = sub;
          } else {
            console.warn('Unknown preferred language ' + language.value + '. Using ' + i18n.language + ' as preferred language.');
          }

          resolve(lang);
        },
        function() {
          console.warn('Could not get preferred language. Using ' + i18n.language + ' as preferred language.');
          resolve(i18n.language);
        }
      );
    });
  }
}

export default new I18n();
